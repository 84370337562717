<template>
    <div>
        <img class="offer-hero-image" :src="photo" />
        
        <div class="offer-wrapper mb-5">
            <h1  v-if="!submitted" class="offer-header mb-4">
                Ange ditt mobilnummer nedan för att prenumerera på Bokaklipp från {{ this.companyName }}
            </h1>
            <div v-if="submitted">
                <h1>
                    Grattis! 
                </h1>
                <h5  v-if="submitted" class="mt-4 mb-8 text-left">
                    Du kommer nu få alla framtida Bokaklipp från {{ this.companyName }}
                </h5>
            </div>
            <!-- <p class="offer-description">
                {{ description }}
            </p> -->
            <div v-if="!submitted" class="container-fluid row">
                <div class="mb-3 col-8">
                    <input type="text" class="form-control form-control-sm"  :class="{ 'is-invalid': errors.phone }" v-model="phoneNumber" placeholder="Ange ditt mobilnummer">
                </div>
                <div class="mb-3 col-4 align-self-center">
                    <div class="btn primary text-right" @click="subscribe">
                        <span>Prenumerera</span>
                    </div>
                </div>
                <label style="font-size: 0.8rem" class="text-danger" v-if="errors.phone"> {{ errors.phone }} </label>
            </div>
        </div>
        <div v-if="submitted" class="mt-12">
            <div class="d-flex flex-column align-items-center">
                <div v-if="hasOptedOut == null || hasOptedOut == false" class="btn offer-opt-out-button text-center" @click="optOut">
                    Jag vill inte ta del av fler erbjudanden från {{ this.companyName }}
                </div>
                <div v-else class="offer-opt-out-button text-center" @click="optOut">
                    Du kommer inte få fler erbjudanden från {{ this.companyName }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { subscribeToTargetList, optOutFromTargetList } from '@/firebase'
import { useRoute } from 'vue-router'
import { getMerchantData } from '../firebase'
import { toast } from 'vue3-toastify'

import logo from '../assets/img/brand/logo.png'

export default {
    name: "OfferView",
    data() {
        return {
            phoneNumber: '',
            companyName: null,
            merchantId: null,
            photo: logo,
            submitted: false,
            errors: {
                phone: null
            }
        }
    },
    async mounted() {
        try {
            const route = useRoute()
            const { 
                merchantId,
            } = route.params
            const merchant = await getMerchantData(merchantId)
            // this.photo = merchant.photoData ?? null
            this.merchantId = merchantId
            this.companyName = merchant.companyName
            
        } catch (error) {
            console.error(error)
        }
    },
    methods: {
        async subscribe() {
            try {
                // validate
                if(this.phoneNumber.length === 0) {
                    this.errors.phone = 'Du måste ange ett giltigt telefonnummer'
                }else {
                    await subscribeToTargetList({
                        phoneNumber: this.sanitizePhoneNumber(this.phoneNumber),
                        userID: this.merchantId,
                    })
                    toast.success('Successfully subscribed to SMS List')
                    this.submitted = true;
                }
            } catch (error) {
                console.error(error)
            }
        },
        async optOut() {
            try {
                await optOutFromTargetList(this.merchantId, this.sanitizePhoneNumber(this.phoneNumber))
                toast.success('Successfully opted out from SMS List')
                this.phoneNumber = ''
                this.submitted = false;
                this.errors = {
                    phone: null
                }
            } catch(error) {
                console.error(error)
            }
        },
        sanitizePhoneNumber(phoneNumber) {
            const cleanedNumber = decodeURI(phoneNumber).replace(/\D+/g, '');
            if (cleanedNumber.startsWith('0')) {
                return `46${cleanedNumber.slice(1)}`;
            } else if (cleanedNumber.startsWith('46')) {
                return cleanedNumber;
            }
            if (cleanedNumber.startsWith('46')) {
                return cleanedNumber;
            }
            return `46${cleanedNumber}`;
        },
    },
    computed: {
    },
}
</script>

<style>
p {
    margin: 0;
}
.offer-hero-image {
    margin: 10%;
    margin-bottom: 100px;
    width: 80%;
    object-fit: contain;
}
.offer-wrapper {
    margin: 0 auto;
    width: 80vw;
    font-family: monospace;
    /* padding-top: 4em; */
    padding-bottom: 20px;
}
.offer-header {
    font-size: 18px;
    padding-bottom: 4em;
}
.offer-description {
    padding-bottom: 1em;
}
.offer-collectionDates {
    padding-bottom: 1em;
}
.offer-your-discount {
    padding-bottom: 2em;
}
.offer-qr-code {
    padding-bottom: 2em;
}
.offer-activate-button {
    padding-bottom: 3em;
}
.qtylabel {
    margin-left: 10px;
}
.offer-opt-out-button {
    background: transparent !important;
    color: black;
    margin-bottom: 2em;
}

.payl-color {
    color: rgb(32, 183, 230);
}
.btn {
  border-color: rgb(32, 183,248);
  background:rgb(32, 183,248);
}
.btn:hover {
  border-color: rgb(28, 161, 218);
  background:rgb(28, 161, 218);
}
.btn-outline-primary {
  background: white;
  color: rgb(32, 183,248);
}
.progress-bar {
  background:rgb(32, 183,248);
}
.btn-outline-primary {
  color: rgb(32, 183, 248);
  border-color: rgb(32, 183, 248);
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: rgb(32, 183, 248);
  border-color: rgb(32, 183, 248);
}
.btn-outline-primary:focus-visible {
  border-color: rgb(32, 183, 248);
}
.btn-outline-primary:active {
  color: #fff;
  background-color: rgb(32, 183, 248);
  border-color: rgb(32, 183, 248);
}
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgb(32 183 248 / 50%);
}
.offer-opt-out-button {
    background: transparent !important;
    /* color: black; */
    margin-bottom: 2em;
    color: red;
    text-decoration: underline;
}
</style>